"use strict";

var _interopRequireDefault = require("F:/Intellij_IDE Workspace/XinBiDaWorkspace/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upload = upload;

var _axios = _interopRequireDefault(require("axios"));

var _auth = require("@/utils/auth");

function upload(api, file) {
  var data = new FormData();
  data.append('file', file);
  var config = {
    headers: {
      'Authorization': (0, _auth.getToken)()
    }
  };
  return _axios.default.post(api, data, config);
}