"use strict";

var _interopRequireDefault = require("F:/Intellij_IDE Workspace/XinBiDaWorkspace/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _store = _interopRequireDefault(require("@/store"));

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
var _default = {
  install: function install(Vue) {
    Vue.prototype.checkPer = function (value) {
      if (value && value instanceof Array && value.length > 0) {
        var roles = _store.default.getters && _store.default.getters.roles;
        var permissionRoles = value;
        return roles.some(function (role) {
          return permissionRoles.includes(role);
        });
      } else {
        console.error("need roles! Like v-permission=\"['admin','editor']\"");
        return false;
      }
    };
  }
};
exports.default = _default;