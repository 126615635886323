"use strict";

var _interopRequireDefault = require("F:/Intellij_IDE Workspace/XinBiDaWorkspace/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.add = add;
exports.default = void 0;
exports.del = del;
exports.edit = edit;
exports.getByType = void 0;
exports.getDicts = getDicts;

var _request = _interopRequireDefault(require("@/utils/request"));

function getDicts() {
  return (0, _request.default)({
    url: 'api/dict/all',
    method: 'get'
  });
}

function add(data) {
  return (0, _request.default)({
    url: 'api/dict',
    method: 'post',
    data: data
  });
}

function del(ids) {
  return (0, _request.default)({
    url: 'api/dict/',
    method: 'delete',
    data: ids
  });
}

function edit(data) {
  return (0, _request.default)({
    url: 'api/dict',
    method: 'put',
    data: data
  });
}

var getByType = function getByType(dictType) {
  return _request.default.getRequest('api/dict/findByType', {
    dictType: dictType
  });
};

exports.getByType = getByType;
var _default = {
  add: add,
  edit: edit,
  del: del
};
exports.default = _default;