"use strict";

var _interopRequireDefault = require("F:/Intellij_IDE Workspace/XinBiDaWorkspace/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

require("F:\\Intellij_IDE Workspace\\XinBiDaWorkspace\\khpf_vue\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("F:\\Intellij_IDE Workspace\\XinBiDaWorkspace\\khpf_vue\\node_modules\\core-js\\modules\\es6.promise.js");

require("F:\\Intellij_IDE Workspace\\XinBiDaWorkspace\\khpf_vue\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("F:\\Intellij_IDE Workspace\\XinBiDaWorkspace\\khpf_vue\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

var _plugins = _interopRequireDefault(require("@/utils/plugins"));

var _Dict = _interopRequireDefault(require("./components/Dict"));

var _permission = _interopRequireDefault(require("@/utils/permission"));

var _Permission = _interopRequireDefault(require("./components/Permission"));

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _routers = _interopRequireDefault(require("./router/routers"));

require("./assets/icons");

require("./router/index");

//
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
// 数据字典
// 权限指令
// import './assets/styles/element-variables.scss'
// global css
// import './assets/styles/index.scss'
// global css
// 代码高亮
// import VueHighlightJS from 'vue-highlightjs'
// import 'highlight.js/styles/atom-one-dark.css'
// icon
// permission control
// import 'echarts-gl'
// import VueCropper from 'vue-cropper'// 图片裁剪
// Vue.use(VueCropper)
_vue.default.use(_permission.default); // Vue.use(VueHighlightJS)
// Vue.use(mavonEditor)


_vue.default.use(_Permission.default);

_vue.default.use(_Dict.default);

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small' // set element-ui default size

});

_vue.default.use(_plugins.default);

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _routers.default,
  store: _store.default,
  created: function created() {
    var _this = this;

    _vue.default.prototype.$store = this.$store; // 深拷贝

    _vue.default.prototype.$clone = function (obj) {
      return _this.$LUtils.clone(obj, true);
    };
  },
  render: function render(h) {
    return h(_App.default);
  }
});