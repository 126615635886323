"use strict";

var _interopRequireDefault = require("F:/Intellij_IDE Workspace/XinBiDaWorkspace/khpf_vue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _lTablePro = _interopRequireDefault(require("l-table-pro"));

var _LTableConfig = _interopRequireDefault(require("./LTableConfig"));

var _DictList = _interopRequireDefault(require("@/components/DictList"));

var _dictText = _interopRequireDefault(require("@/components/DictList/dict-text"));

var _request = _interopRequireDefault(require("@/utils/request"));

var _ImgViewer = _interopRequireDefault(require("@/components/ImgViewer"));

var components = {
  'dict-list': _DictList.default,
  'dict-text': _dictText.default,
  'img-viewer': _ImgViewer.default
};
var _default = {
  install: function install(Vue, options) {
    // 注册自定义组件
    Object.keys(components).forEach(function (key) {
      Vue.component(key, components[key]);
    });
    Vue.use(_lTablePro.default);
    Vue.prototype.$request = _request.default; // 默认配置

    _LTableConfig.default.init(_lTablePro.default);
  }
};
exports.default = _default;